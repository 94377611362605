<template>
  <div id="mainscreen" :style="mainstyle">
    <NavComp />
    <router-view />
  </div>
  <LandingComp msg="Nuestra Visión y Misión" />
  <FooterComp />
</template>
<script>
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-unused-vars
import bootstrap from "bootstrap";
import NavComp from "@/components/NavComp.vue";
import LandingComp from "@/components/LandingComp.vue";
import FooterComp from "@/components/FooterComp.vue";
export default {
  components: {
    NavComp,
    LandingComp,
    FooterComp,
  },
  data() {
    return {
      mainstyle: {
        backgroundImage: `url(${require("@/assets/pic_1.jpg")})`,
        height: "900px",
      },
    };
  },
};
</script>
<style lang="scss">
body {
  background: #e07409;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f4f6f8;
}
#mainscreen {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1200px;
  margin: auto;
  overflow: auto;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <footer class="container" :style="footerstyle">
    <div class="row">
      <div class="col" id="col_1">
        <h3>GALPONES</h3>
        <ul>
          <li><h4>Proyecto</h4></li>
          <li><h4>Construcción</h4></li>
          <li><h4>Estructuras Metálicas</h4></li>
        </ul>
        <img src="@/assets/logo_ghya.png" alt="GHYASA" />
      </div>
      <div class="col" id="col_2">
        <h3>Contáctanos:</h3>
        <ul>
          <li id="mail">
            <em><i class="bi bi-envelope"></i></em
            ><a href="contacto@ghyasa.com">contacto@ghyasa.com</a>
          </li>
          <li id="linkedin">
            <em><i class="bi bi-linkedin"></i></em
            ><a href="https://www.linkedin.com/company/ghyasa/">Linkedin</a>
          </li>
          <li id="address">
            <em><i class="bi bi-geo-alt-fill"></i></em
            ><a href="https://goo.gl/maps/XaywgxMVjoDRRjhP6"
              >10.188546, -67.958725 Parque Comercial Industrial Castillito, San
              Diego Carabobo Venezuela</a
            >
          </li>
        </ul>
      </div>
      <div class="col" id="col_3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1882.0176617253774!2d-67.9593704907374!3d10.18874027373624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sve!4v1673013836614!5m2!1ses-419!2sve"
          height="300"
          style="border: 0"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </footer>
</template>
<script>
import "bootstrap-icons/font/bootstrap-icons.css";
export default {
  name: "FooterComp",
  data() {
    return {
      footerstyle: {
        backgroundImage: `url(${require("@/assets/pic_5.jpg")})`,
      },
    };
  },
};
</script>
<style scoped>
footer {
  margin-top: 50px;
  padding-top: 40px;
  margin-bottom: 50px;
  padding-bottom: 40px;
  box-shadow: 6px 6px 14px #181716;
}
.col {
  margin-top: 20px;
  max-width: 350px;
}
#col_1 {
  color: #ffc107;
  box-shadow: 2px 2px 8px #181716;
}
#col_2 ul {
  list-style: none;
  text-align: left;
}
#col_2 ul > li {
  font-size: 20px;
  margin-bottom: 10px;
}
#col_2 ul > li em {
  font-size: 24px;
  background: #201e1e;
  padding: 5px;
}
#mail em {
  color: #ffc107;
}
#linkedin em {
  color: #0e76a8;
}
#address em {
  color: red;
}
#col_2 ul > li a {
  color: unset;
  text-decoration: none;
  margin-left: 20px;
  text-shadow: 2px 2px #ed7805;
}
#col_1 h3 {
  text-shadow: 3px 3px #ed7805;
}
#col_1 ul {
  list-style: none;
  text-align: left;
  text-shadow: 2px 2px #ed7805;
}
#col_3 {
  box-shadow: 2px 2px 8px #181716;
}
#col_3 iframe {
  width: 380px;
}
</style>

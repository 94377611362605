// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default class Firebase {
  // eslint-disable-next-line
  constructor() {
    //const fileName = "Articles_pictures";
    // TODO: Replace the following with your app's Firebase project configuration
    const firebaseConfig = {
      apiKey: "AIzaSyDYxolSkffdXBrLmjbIanUidNAcz_baga4",
      authDomain: "ghyasa-cf25e.firebaseapp.com",
      projectId: "ghyasa-cf25e",
      storageBucket: "ghyasa-cf25e.appspot.com",
      messagingSenderId: "139211971181",
      appId: "1:139211971181:web:c3b39e1b70733058e93d64",
      measurementId: "G-22F49R1Y9D",
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // eslint-disable-next-line
    const analytics = getAnalytics(app);
    // Initialize Cloud Storage and get a reference to the service
    this.storage = getStorage(app);
    //database
    this.db = getFirestore(app);
  }
  //set the image in PersonalCard.vue
  setImage(fileName, imgid) {
    getDownloadURL(ref(this.storage, fileName))
      .then((url) => {
        const image = document.getElementById(imgid);
        image.setAttribute("src", url);
      })
      .catch((error) => {
        console.log("el error fue: ", error);
      });
  }
  //get URL image for NavBar.vue
  async getURLbackground(fileName) {
    const backURL = await getDownloadURL(ref(this.storage, fileName));
    return backURL;
  }
  // Get a list of documents from your database
  async getDocuments(docName) {
    const Col = collection(this.db, docName);
    const colSnapshot = await getDocs(Col);
    const colList = colSnapshot.docs.map((doc) => doc.data());
    return colList;
  }
}

<template>
  <h2>{{ msg }}</h2>
  <div class="container flex-column">
    <div class="row justify-content-between">
      <div class="col col-sm-6">
        <h3>Introducción</h3>
        <p>
          En el año 2004 nace GHYA,S.A. con el propósito de ofrecer servicios en
          el estado carabobo dirigidos al sector construcción especializándose
          mayormente a la fabricación de edificaciones de tipo comercial e
          industrial.
        </p>
        <h3>Misión</h3>
        <p>
          En GHYASA nos enfocamos a diario para mejorar y ofrecer un óptimo
          servicio para el desarrollo de proyectos y obras con relación a los
          galpones tanto de uso comercial e industrial, de la mano de un
          personal técnico y administrativo capacitado para velar por el óptimo
          cumplimiento de las normas de construcción y planeamiento urbano
          vigentes.
        </p>
        <h3>Visión</h3>
        <p>
          Convertirnos en una empresa competitiva y reconocida tanto en nuestro
          sector comercial como en la zona donde nos desarrollamos creando
          también un ambiente propicio para nuestros trabajadores y
          profesionales afiliados.
        </p>
      </div>
      <div class="col col-sm-6">
        <!--<img src="@/assets/pic_2.jpg" alt="galpón en castillito" />-->
        <CarouselBarTwo :pictures="topics" class="carousel" />
      </div>
    </div>
    <div class="row bg-dark justify-content-between mt-5" id="values">
      <div class="col col-sm-5" id="foundation">
        <img
          src="@/assets/pic_3.png"
          alt="fundación superficial o zapata ghyasa"
        />
      </div>
      <div class="col col-sm-7">
        <h2>Nuestros Principios</h2>
        <p>
          Nuestro trabajo se define por nuestros principios de compromiso,
          innovación y colaboración
        </p>
        <div class="container d-flex justify-content-around cardholder">
          <div class="card">
            <div class="card-body text-dark">
              <h5 class="card-title">Compromiso</h5>
              <img class="puntos" src="@/assets/dot.png" alt="puntos" />
              <p class="card-text">
                Escuchamos, entendemos y ejecutamos según la visión del cliente.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body text-dark">
              <h5 class="card-title">Innovación</h5>
              <img class="puntos" src="@/assets/dot.png" alt="puntos" />
              <p class="card-text">
                Llevamos a cabo nuestro trabajo en forma óptima y efectiva.
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-body text-dark">
              <h5 class="card-title">Colaboración</h5>
              <img class="puntos" src="@/assets/dot.png" alt="puntos" />
              <p class="card-text">
                Trabajamos de la mano de nuestros clientes brindándoles total
                transparencia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CarouselBarTwo from "@/components/CarouselBarTwo.vue";
export default {
  name: "LandingComp",
  components: {
    CarouselBarTwo,
  },

  props: {
    msg: String,
  },
  data() {
    return {
      topics: [],
    };
  },
  created() {
    this.$store.dispatch("firebaseGetDocuments", "MisionVision_Documents");
    this.$store.getters.getArticleDocuments.then((docs) => {
      this.topics = docs;
    });
  },
};
</script>
<style scoped>
h2 {
  margin-top: 60px;
  margin-bottom: 20px;
}
.h5 {
  font-size: 1.2rem;
}
img {
  width: 100%;
  height: auto;
}
#values {
  padding-bottom: 40px;
}
.puntos {
  max-width: 34px;
}
.row p,
.card-text {
  text-align: justify;
  text-justify: inter-word;
}
.col {
  min-width: 100%;
}
.card {
  max-width: 30%;
}
@media (min-width: 1000px) {
  .col {
    min-width: 400px;
  }
}
</style>

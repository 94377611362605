import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/nosotros",
    name: "nosotros",
    component: () =>
      import(/* webpackChunkName: "nosotros" */ "../views/AboutView.vue"),
  },
  {
    path: "/proyectos",
    name: "proyectos",
    component: () =>
      import(/* webpackChunkName: "proyectos" */ "../views/ProyectosView.vue"),
    children: [
      {
        path: "todos",
        component: () =>
          import(
            /* webpackChunkName: "personal" */ "../components/ProjectLanding.vue"
          ),
      },
      {
        path: "ghyasa",
        component: () =>
          import(
            /* webpackChunkName: "personal/linkedin" */ "../components/GhyasaProjectPage.vue"
          ),
        children: [
          {
            path: ":ghyasaname",
            component: () =>
              import(
                /* webpackChunkName: "personal/linkedin" */ "@/components/GhyasaPageResume.vue"
              ),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/personal",
    name: "personal",
    component: () =>
      import(/* webpackChunkName: "personal" */ "../views/PersonalView.vue"),
    children: [
      {
        path: "todos",
        component: () =>
          import(
            /* webpackChunkName: "personal" */ "../components/PersonalLanding.vue"
          ),
      },
      {
        path: "linkedin",
        component: () =>
          import(
            /* webpackChunkName: "personal/linkedin" */ "../components/LinkedinPage.vue"
          ),
        children: [
          {
            path: ":linkedinname",
            component: () =>
              import(
                /* webpackChunkName: "personal/linkedin" */ "@/components/LinkedinPageResume.vue"
              ),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/empleo",
    name: "empleo",
    component: () =>
      import(/* webpackChunkName: "emplleo" */ "../views/EmpleoView.vue"),
  },
  {
    path: "/contacto",
    name: "contacto",
    component: () =>
      import(/* webpackChunkName: "contacto" */ "../views/ContactoView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { createStore } from "vuex";
import FireBase from "@/services/Firebase.js";
export default createStore({
  state: {
    Article_Documents: "",
    picture: "",
    topics_dir_control: [],
    topics_dir: [],
  },
  getters: {
    getArticleDocuments(state) {
      return state.Article_Documents;
    },
    getTopicsDir(state) {
      return state.topics_dir;
    },
    getPicture(state) {
      return state.picture;
    },
  },
  mutations: {
    setArticleDocuments(state, objDoc) {
      state.Article_Documents = objDoc;
    },
    mutateTopicDir(state, objdir) {
      if (!state.topics_dir_control.includes(objdir.title)) {
        state.topics_dir_control.push(objdir.title);
        state.topics_dir.push(objdir);
      }
    },
    setPicture(state, bg) {
      state.picture = bg;
    },
  },
  actions: {
    firebaseGetDocuments(context, docName) {
      const objDoc = new FireBase().getDocuments(docName);
      context.commit("setArticleDocuments", objDoc);
    },
    firebaseSetImage(context, { directory, imgID }) {
      new FireBase().setImage(directory, imgID);
    },
    setTopicsDir(context, objdir) {
      context.commit("mutateTopicDir", objdir);
    },
    firebaseSetpicture(context, { directory }) {
      const bg = new FireBase().getURLbackground(directory);
      context.commit("setPicture", bg);
    },
  },
  modules: {},
});

<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid align-items-end">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/logo_ghya.png" alt="GHYASA" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link fs-5 fw-bold" to="/nosotros"
              >Nosotros</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link fs-5 fw-bold" to="/proyectos/todos"
              >Proyectos</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link fs-5 fw-bold" to="/personal/todos"
              >Personal</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link fs-5 fw-bold" to="/empleo"
              >Empleo</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link fs-5 fw-bold" to="/contacto"
              >Contacto</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "NavComp",
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
nav {
  font-family: "Roboto Condensed", sans-serif;
  border-bottom: 2px solid #ff7f00;
  margin-bottom: 30px;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255 127 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler {
  color: #ff7f00;
}
nav a.router-link-exact-active {
  color: #ff7f00;
}
.nav-link {
  color: white;
}
</style>

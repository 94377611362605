<template>
  <div class="home">
    <h1>GHYA, S.A.</h1>
    <h2>Diseño de galpones.</h2>
    <h2>Diseño de estructuras de acero.</h2>
    <h2>Fabricación de estructuras de acero.</h2>
    <h2>Montaje de estructuras de acero.</h2>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
};
</script>
<style scoped>
img {
  width: 100%;
  height: auto;
}
.home {
  color: #ff7f00;
  text-shadow: 2px 2px 1px #1c1b19;
  margin-top: 100px;
}
</style>
